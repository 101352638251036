var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('k-crud-table',{attrs:{"headers":_vm.cameraColumns,"items":_vm.cruddataMX.dataList,"value":_vm.cruddataMX.pagination.current,"total":_vm.cruddataMX.pagination.total,"item-per-page":2},on:{"pageChanged":function (page) {
      _vm.crudPaginationMX().change(page);
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('div',{staticClass:"text-h5"},[_vm._v("软件版本管理")]),_c('v-spacer'),(_vm.hasPermission(['software.data.add']))?_c('k-primary-button',{attrs:{"icon":"mdi-plus","text":"添加"},on:{"click":function($event){_vm.crudActionMX().newItem(_vm.crud.default, function () {
            _vm.onOpenEdit(_vm.crud.default);
          })}}}):_vm._e(),_c('k-crud-dialog',{attrs:{"maxwidth":_vm.crud.widths,"title":_vm.crud.title,"actionshow":false},on:{"save":function () {
            _vm.crudActionMX().saveItem();
          },"close":function () {
            _vm.crudActionMX().closeEditingPage(_vm.crud);
          }},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [_c('v-form',{ref:_vm.crud.form.ref},[_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('k-text-input',{staticClass:"my-2",attrs:{"max-length":"100","label":"文件名称","rules":[_vm.globalRules.empty]},model:{value:(_vm.cruddataMX.data.fileName),callback:function ($$v) {_vm.$set(_vm.cruddataMX.data, "fileName", $$v)},expression:"cruddataMX.data.fileName"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{staticClass:"flie"},[_c('span',{staticClass:"my-2 wenjian"},[_vm._v("文件：")]),_c('c-upload',{attrs:{"append-url-params":{
                        fileName: _vm.cruddataMX.data.fileName,
                      },"accept":".","upload-type":"btn","disabled":!_vm.cruddataMX.data.fileName,"api":_vm.uploadFile},on:{"uploadSuccess":function($event){_vm.crudConditionsMX().resetPage()}},model:{value:(_vm.cruddataMX.data.file),callback:function ($$v) {_vm.$set(_vm.cruddataMX.data, "file", $$v)},expression:"cruddataMX.data.file"}})],1)])],1)],1)],1)]},proxy:true}]),model:{value:(_vm.crud.switch),callback:function ($$v) {_vm.$set(_vm.crud, "switch", $$v)},expression:"crud.switch"}})],1)]},proxy:true},{key:"actions",fn:function(ref){
                      var item = ref.item;
return [(_vm.hasPermission(['hardware.psm.delete']))?_c('k-table-error-btn',{attrs:{"text":"删除"},on:{"click":function($event){_vm.crudActionMX().deleteItem(item.id)}}}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }