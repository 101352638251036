<template>
  <k-crud-table
    :headers="cameraColumns"
    :items="cruddataMX.dataList"
    :value="cruddataMX.pagination.current"
    :total="cruddataMX.pagination.total"
    :item-per-page="2"
    @pageChanged="
      (page) => {
        crudPaginationMX().change(page);
      }
    "
  >
    <template #top>
      <v-toolbar flat>
        <div class="text-h5">软件版本管理</div>
        <v-spacer></v-spacer>
        <k-primary-button
          v-if="hasPermission(['software.data.add'])"
          icon="mdi-plus"
          text="添加"
          @click="
            crudActionMX().newItem(crud.default, () => {
              onOpenEdit(crud.default);
            })
          "
        />
        <k-crud-dialog
          v-model="crud.switch"
          :maxwidth="crud.widths"
          :title="crud.title"
          :actionshow="false"
          @save="
            () => {
              crudActionMX().saveItem();
            }
          "
          @close="
            () => {
              crudActionMX().closeEditingPage(crud);
            }
          "
        >
          <!-- //新增 -->
          <template #dialogContent>
            <v-form :ref="crud.form.ref">
              <v-container>
                <v-row no-gutters>
                  <v-col>
                    <k-text-input
                      v-model="cruddataMX.data.fileName"
                      max-length="100"
                      label="文件名称"
                      :rules="[globalRules.empty]"
                      class="my-2"
                    />
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col>
                    <div class="flie">
                      <span class="my-2 wenjian">文件：</span>
                      <c-upload
                        v-model="cruddataMX.data.file"
                        :append-url-params="{
                          fileName: cruddataMX.data.fileName,
                        }"
                        accept="."
                        upload-type="btn"
                        :disabled="!cruddataMX.data.fileName"
                        :api="uploadFile"
                        @uploadSuccess="crudConditionsMX().resetPage()"
                      ></c-upload>
                    </div> </v-col
                ></v-row>
              </v-container>
            </v-form>
          </template>
        </k-crud-dialog>
      </v-toolbar>
    </template>
    <template #actions="{ item }">
      <k-table-error-btn
        v-if="hasPermission(['hardware.psm.delete'])"
        text="删除"
        @click="crudActionMX().deleteItem(item.id)"
      >
      </k-table-error-btn>
    </template>
  </k-crud-table>
</template>
<script>
import { uploadFile } from '@/api/upload.js';

export default {
  props: {
    value: undefined,
    dosearchflg: { type: Boolean, default: true },
  },
  data() {
    return {
      uploadFile,
      showProtocol: '',
      crud: {
        name: '软件管理',
        title: '', // 在Mixin中被修改
        switch: false,
        widths: '700',
        uploading: false,
        loading: false,
        form: {
          ref: 'accountModalForm1',
        },
        select: {
          roles: {
            loading: false,
            current: 1,
            defaultPageSize: 10,
            data: [],
            dataset: [],
          },
        },
        default: {
          file: '',
          fileName: '',
        },
      },
      ver_supportRules: [
        (v) => !!v || '版本号不能为空',
        (v) => /^([1-9]\d|[1-9])(.([1-9]\d|\d)){2}$/.test(v) || '版本号不规范',
      ],
      cameraColumns: [
        { text: '序号', value: 'indexNum', sortable: false, align: 'center' },
        { text: '版本号', value: 'ver_name', sortable: false, align: 'center' },
        {
          text: '支持版本',
          value: 'ver_support',
          sortable: false,
          align: 'center',
        },
        {
          text: '文件大小',
          value: 'file_size',
          sortable: false,
          align: 'center',
        },
        {
          text: '下载路径',
          value: 'download_path',
          sortable: false,
          align: 'center',
        },
        { text: 'MD5', value: 'md5', sortable: false, align: 'center' },
        { text: '系统', value: 'machine', sortable: false, align: 'center' },
        {
          text: '备注',
          value: 'description',
          sortable: false,
          align: 'center',
        },

        { text: '操作', value: 'actions', sortable: false, align: 'center' },
      ],
      rules: {
        required: (value) => !!value || 'Required.',
      },
      protocolList: [],
      organizationList: [],
      commonAreaList: [],
      rtspShowFlg: false,
    };
  },
  computed: {},
  watch: {
    value() {
      this.cruddataMX.conditions.searchParam = this.value;
    },
    dosearchflg() {
      this.crudConditionsMX().resetPage();
    },
  },
  created() {
    this.crudInitMX(
      this.crud,
      {
        list: this.$api.software.software.getversion,
        add: this.$api.software.software.getAddList,
        delete: this.$api.software.software.getdeleteCamera,
      },

      {},

      {
        add: ['file', 'ver_support', 'fileName'],
      }
    );
  },
  methods: {
    onOpenEdit(item) {
      item.roles = [];
      item.roleUuids = [];
      item.roleVos?.forEach((p) => {
        item.roles.push(p.name);
        item.roleUuids.push(p.uuid);
      });
      // this.showProtocol = item.protocol;
    },
  },
};
</script>
<style lang="scss" scoped>
.col_width {
  width: 50%;
}
.flie {
  display: flex;
  align-items: center;
}
.wenjian {
  width: 107px;
  text-align: right;
}
</style>
